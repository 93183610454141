<template>
  <div>
    <Calendar :loading="x_loading" :events="events" />
  </div>
</template>

<script>
import { INDIVIDUAL_TASKS_GET } from "@/store/actions/task";
export default {
  data() {
    return {
      events: [],
    };
  },
  methods: {
    async getEvents() {
      let resp = await this.$dispatch(INDIVIDUAL_TASKS_GET);
      if (resp.success) {
        let events = []
        for (let e in resp.result) {
          for (let u in resp.result[e].to_users) {
            if (
              resp.result[e].to_users[u].id == this.$store.getters.profile.id
            ) {
              events.push(resp.result[e]);
            }
          }
        }
        this.events = events
      } else {
        alert(resp);
      }
    },
  },
  components: {
    Calendar: () =>
      import(
        /* webpackChunkName: "calendar" */
        "@/components/Calendar"
      ),
  },

  // updated() {
  //   this.getEvents()
  // },

  created() {
    this.getEvents();
    this.$root.$refs.Tasks = this;
  },
};
</script>

<style>
</style>